import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

import Badge from 'shopper/components/Badge';

import { hasHighlightBadge } from 'lib/offer';

import OFFER from 'constants/offer';

const OfferCardBadges = ({
  className,
  offerIsHighlight,
  offerStatusName,
  offerUserVisibility,
}) => {
  const hasExclusiveBadge =
    offerUserVisibility === OFFER.VISIBILITY_STATUS.LOGGED_ONLY;
  const hasFinishedBadge = offerStatusName === OFFER.STATUSES.FINISHED;

  return (
    <div className={twMerge('absolute top-0 flex justify-end', className)}>
      <div className="z-10 flex flex-wrap">
        {offerStatusName === OFFER.STATUSES.TOP_OFFER &&
          !hasExclusiveBadge &&
          !hasHighlightBadge(offerIsHighlight, offerStatusName) && (
            <Badge type="highlight">{OFFER.BADGES.LOWEST_PRICE}</Badge>
          )}
        {hasExclusiveBadge && !hasFinishedBadge && (
          <Badge type="secondary">{OFFER.BADGES.EXCLUSIVE}</Badge>
        )}
        {hasFinishedBadge && <Badge>{OFFER.BADGES.FINISHED}</Badge>}
        {hasHighlightBadge(offerIsHighlight, offerStatusName) &&
          !hasExclusiveBadge && (
            <Badge type="primary">{OFFER.BADGES.RECOMMENDED}</Badge>
          )}
      </div>
    </div>
  );
};

OfferCardBadges.propTypes = {
  offerIsHighlight: PropTypes.bool.isRequired,
  offerStatusName: PropTypes.string.isRequired,
  offerUserVisibility: PropTypes.string.isRequired,
};

export default OfferCardBadges;
